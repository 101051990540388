import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';
import { generateUrlForStates } from '../../../utils';
import BaseComponent from '../../base/base-component';
import { ICyclePercentChartPoint } from '../../components/charts/cycle-percent/cycle-percent-interface';
import { ICycleScoreChartPoint } from '../../components/charts/cycle-score/cycle-score-interface';
import { IDemographicParityChartProperties } from '../../components/charts/demographic-parity/demographic-parity-interface';
import ContainerBox from '../../components/container-box/container-box';
import CriteriaGrade from '../../components/criteria-grade/criteria-grade';
import DemographicParity from '../../components/demographic-parity/demographic-parity';
import { IDemographicParityItem } from '../../components/demographic-parity/demographic-parity-interface';
import RepresentationDetails from '../../components/details/representation/representation-details';
import ImproveBox from '../../components/improve-box/improve-box';
import Share from '../../components/share/share';
import SocialMetadata from '../../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../../components/social-metadata/social-metadata-enum';
import StateLayout from '../../components/state-layout/state-layout';
import { ISimilarStatesItem } from '../../components/states/similar-states/item/item-interface';
import SimilarStates from '../../components/states/similar-states/similar-states';
import {
  SODComponents,
  SODCycles,
  SODGrade,
  SODMeasures,
  SODSubComponents,
} from '../../global/global-enum';
import { IGraphQLNode, ISODNode } from '../../global/global-interfaces';
import {
  IPopulateRaceComponentDataParameters,
  IPopulateRaceComponentDataResult,
  IPopulateSexComponentDataParameters,
  IPopulateSexComponentDataResult,
  IStateRepresentationTemplateProperties,
} from './state-representation-interface';

export default class StateRepresentationTemplate extends BaseComponent<IStateRepresentationTemplateProperties> {
  protected declareTranslateCollection(): string | undefined {
    return 'stateRepresentationTemplate';
  }

  public render(): ReactNode {
    const { data, pageContext } = this.props;
    const { breadCrumbs, state, pageContent } = pageContext;
    const {
      representationFemaleParity,
      representationNonWhiteParity,
      representationTotal,
      lesserScoreSimilarStates,
      greaterScoreSimilarStates,
      femaleDemoGraphic,
      nonWhiteDemoGraphic,
      icon,
    } = data;
    const {
      representationScoreDesc,
      improveRepresentationDesc,
      improveRepresentationSolution1,
      improveRepresentationSolution2,
      improveRepresentationSolution3,
      sexParityDesc,
      raceParityDesc,
      femaleDesc,
      nonWhiteDesc,
    } = pageContent;
    const representationAverage = this.populateAverageData(SODComponents.TOTAL);
    const femaleAverage = this.populateAverageData(
      SODComponents.REPRESENTATION_FEMALE
    );
    const nonWhiteAverage = this.populateAverageData(
      SODComponents.REPRESENTATION_NON_WHITE
    );

    const representationOverall = this.populateOverallData(SODComponents.TOTAL);
    const femaleOverall = this.populateOverallData(
      SODComponents.REPRESENTATION_FEMALE
    );
    const nonWhiteOverall = this.populateOverallData(
      SODComponents.REPRESENTATION_NON_WHITE
    );

    const totalCycleScores: ICycleScoreChartPoint[] =
      representationTotal.nodes.map((node) => {
        return {
          cycle: node.cycle,
          score: Math.round(node.score * 100),
          grade: node.grade,
          weight: node.weight,
        };
      });
    const femaleParityCyclePercents: ICyclePercentChartPoint[] =
      representationFemaleParity.nodes.map((node) => {
        return {
          cycle: node.cycle,
          percent: Math.round(node.score * 100),
        };
      });
    const nonWhiteParityCyclePercents: ICyclePercentChartPoint[] =
      representationNonWhiteParity.nodes.map((node) => {
        return {
          cycle: node.cycle,
          percent: Math.round(node.score * 100),
        };
      });

    const femaleDemoGraphicItem: IDemographicParityItem = {
      title: this.translate('female'),
      description: femaleDesc,
      ...this.populateDemographicData(femaleDemoGraphic),
    };
    const nonWhiteDemoGraphicItem: IDemographicParityItem = {
      title: this.translate('nonWhite'),
      description: nonWhiteDesc,
      ...this.populateDemographicData(nonWhiteDemoGraphic),
    };

    const lesserScoreSimilarStatesItems = lesserScoreSimilarStates.nodes.map(
      (node): ISimilarStatesItem => {
        return {
          abbreviation: node.code,
          score: Math.round(node.score * 100),
          title: node.state,
          grade: node.grade,
          itemLink: `/state/${generateUrlForStates(node.state)}/representation`,
        };
      }
    );

    const greaterScoreSimilarStatesItems = greaterScoreSimilarStates.nodes.map(
      (node): ISimilarStatesItem => {
        return {
          abbreviation: node.code,
          score: Math.round(node.score * 100),
          title: node.state,
          grade: node.grade,
          itemLink: `/state/${generateUrlForStates(node.state)}/representation`,
        };
      }
    );

    const { sexAverage, sexOverall, sexParityCyclePercents } =
      this.populateSexComponentData({
        femaleAverage,
        femaleParityCyclePercents,
        femaleOverall,
      });
    const { raceAverage, raceOverall, raceParityCyclePercents } =
      this.populateRaceComponentData({
        nonWhiteAverage,
        nonWhiteParityCyclePercents,
        nonWhiteOverall,
      });

    const stateUrl: string = generateUrlForStates(state);
    const { publicURL: iconUrl = '' } = icon.nodes[0];

    return (
      <StateLayout
        state={state}
        image={iconUrl}
        breadCrumbItems={breadCrumbs}
        title={`${this.translate('title')}(${representationOverall.state})`}
        description={this.translate('firstScoreDescription')}
      >
        <SocialMetadata
          type={SocialMetadataType.SUMMARY}
          title={`${representationOverall.state} ${this.translate('title')}`}
          description={this.translate('firstScoreDescription')}
          imageResourceFileName={`embedded-representation-score-${generateUrlForStates(
            representationOverall.state
          )}.png`}
        />
        <ContainerBox
          id="representation"
          className="c-section--score is-criteria-grade"
        >
          <div className={'c-score'}>
            <CriteriaGrade
              title={this.translate('scoreTitle')}
              averageScore={Math.round(representationAverage.score * 100)}
              averagePercent={Math.round(representationAverage.value * 100)}
              overallPercentage={Math.round(representationOverall.value * 100)}
              cycleScores={totalCycleScores}
              overallGrade={representationOverall.grade}
              overallScore={Math.round(representationOverall.score * 100)}
              descriptions={[representationScoreDesc]}
            />
            <Share
              absoluteLink={`/state/${stateUrl}/representation`}
              embeddedLink={`/embedded/representation/${stateUrl}`}
              imageResourceFileName={`embedded-representation-score-${generateUrlForStates(
                representationOverall.state
              )}.png`}
            />
          </div>
        </ContainerBox>
        <ContainerBox className="c-section-box c-section--similar">
          <SimilarStates
            lesserScoreStates={lesserScoreSimilarStatesItems}
            greaterScoreStates={greaterScoreSimilarStatesItems}
            activeState={{
              abbreviation: representationOverall.code,
              grade: representationOverall.grade,
              score: Math.round(representationOverall.score * 100),
              title: representationOverall.state,
              itemLink: `/state/${generateUrlForStates(
                representationOverall.state
              )}/representation`,
            }}
          />
        </ContainerBox>
        <ContainerBox className="c-section-box c-section--improve">
          <ImproveBox
            improveBoxItems={{
              title: this.translate('improveRepresentation'),
              description: improveRepresentationDesc,
              items: [
                improveRepresentationSolution1,
                improveRepresentationSolution2,
                improveRepresentationSolution3,
              ],
            }}
          />
        </ContainerBox>
        <ContainerBox className="c-section-box c-section--details">
          <RepresentationDetails
            items={[
              {
                title: this.translate('sexParity'),
                description: sexParityDesc,
                value: Math.round(sexOverall.value * 100),
                averageValue: Math.round(sexAverage.value * 100),
                chartData: sexParityCyclePercents,
              },
              {
                title: this.translate('raceParity'),
                description: raceParityDesc,
                value: Math.round(raceOverall.value * 100),
                averageValue: Math.round(raceAverage.value * 100),
                chartData: raceParityCyclePercents,
              },
            ]}
          />
        </ContainerBox>
        <ContainerBox className="c-section-box c-section--demography">
          <DemographicParity
            items={[femaleDemoGraphicItem, nonWhiteDemoGraphicItem]}
          />
        </ContainerBox>
      </StateLayout>
    );
  }

  //We only have female data. so " 1 - female " is the male data.
  private populateSexComponentData(
    param: IPopulateSexComponentDataParameters
  ): IPopulateSexComponentDataResult {
    const { femaleAverage, femaleParityCyclePercents, femaleOverall } = param;
    const sexAverage: ISODNode = {
      ...femaleAverage,
      grade: SODGrade.GRADE_NA,
      score: (1 - femaleAverage.score) / femaleAverage.score,
      value: (1 - femaleAverage.value) / femaleAverage.value,
      weight: -1, //not required so we dont calculate it
      component: SODComponents.REPRESENTATION_SEX,
    };

    const sexOverall: ISODNode = {
      ...femaleOverall,
      grade: SODGrade.GRADE_NA,
      score: (1 - femaleOverall.score) / femaleOverall.score,
      value: (1 - femaleOverall.value) / femaleOverall.value,
      weight: -1, //not required so we dont calculate it
      component: SODComponents.REPRESENTATION_SEX,
    };

    const sexParityCyclePercents: ICyclePercentChartPoint[] =
      femaleParityCyclePercents.map((item) => {
        const { cycle, percent } = item;
        return {
          cycle,
          percent: Math.round(((100 - percent) / percent) * 100),
        };
      });

    return {
      sexAverage,
      sexOverall,
      sexParityCyclePercents,
    };
  }

  //We only have nonWhite data. so " 1 - nonWhite " is the white data.
  private populateRaceComponentData(
    param: IPopulateRaceComponentDataParameters
  ): IPopulateRaceComponentDataResult {
    const { nonWhiteAverage, nonWhiteParityCyclePercents, nonWhiteOverall } =
      param;
    const raceAverage: ISODNode = {
      ...nonWhiteAverage,
      grade: SODGrade.GRADE_NA,
      score: (1 - nonWhiteAverage.score) / nonWhiteAverage.score,
      value: (1 - nonWhiteAverage.value) / nonWhiteAverage.value,
      weight: -1, //not required so we dont calculate it
      component: SODComponents.REPRESENTATION_RACE,
    };

    const raceOverall: ISODNode = {
      ...nonWhiteOverall,
      grade: SODGrade.GRADE_NA,
      score: (1 - nonWhiteOverall.score) / nonWhiteOverall.score,
      value: (1 - nonWhiteOverall.value) / nonWhiteOverall.value,
      weight: -1, //not required so we dont calculate it
      component: SODComponents.REPRESENTATION_RACE,
    };

    const raceParityCyclePercents: ICyclePercentChartPoint[] =
      nonWhiteParityCyclePercents.map((item) => {
        const { cycle, percent } = item;
        return {
          cycle,
          percent: Math.round(((100 - percent) / percent) * 100),
        };
      });

    return {
      raceAverage,
      raceOverall,
      raceParityCyclePercents,
    };
  }

  private populateOverallData(component: SODComponents): ISODNode {
    const { overall } = this.props.data;
    const componentOverall: ISODNode = overall.nodes.find((node) => {
      return node.component === component;
    }) ?? {
      code: 'NA',
      component,
      cycle: SODCycles.OVERALL,
      grade: SODGrade.GRADE_NA,
      measure: SODMeasures.REPRESENTATION,
      score: -1,
      state: 'NA',
      value: -1,
      weight: -1,
    };

    return componentOverall;
  }

  private populateAverageData(component: SODComponents): ISODNode {
    const { average } = this.props.data;
    const componentAverage: ISODNode = average.nodes.find((node) => {
      return node.component === component;
    }) ?? {
      code: 'NA',
      component,
      cycle: SODCycles.OVERALL,
      grade: SODGrade.GRADE_NA,
      measure: SODMeasures.REPRESENTATION,
      score: -1,
      state: 'NA',
      value: -1,
      weight: -1,
    };
    return componentAverage;
  }

  private populateDemographicData(
    subComponentData: IGraphQLNode<ISODNode>
  ): IDemographicParityChartProperties {
    return {
      population: Math.round(
        subComponentData.nodes.find(
          (demographic) =>
            demographic.subComponent ===
            SODSubComponents.REPRESENTATION_POPULATION
        ).value * 100
      ),
      legislature: Math.round(
        subComponentData.nodes.find(
          (demographic) =>
            demographic.subComponent ===
            SODSubComponents.REPRESENTATION_LEGISLATURE
        ).value * 100
      ),
      parity: Math.round(
        subComponentData.nodes.find(
          (demographic) =>
            demographic.subComponent === SODSubComponents.REPRESENTATION_PARITY
        ).value * 100
      ),
    };
  }
}
export const query = graphql`
  query StateRepresentation($state: String, $score: Float) {
    representationTotal: allSheetDataRepresentation(
      filter: {
        state: { eq: $state }
        component: { eq: "Total" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
        value
        weight
      }
    }
    representationFemaleParity: allSheetDataRepresentation(
      filter: {
        state: { eq: $state }
        component: { eq: "Female" }
        subComponent: { eq: "Parity" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
      }
    }
    representationNonWhiteParity: allSheetDataRepresentation(
      filter: {
        state: { eq: $state }
        component: { eq: "NonWhite" }
        subComponent: { eq: "Parity" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
      }
    }
    lesserScoreSimilarStates: allSheetDataRepresentation(
      sort: { fields: score, order: DESC }
      filter: {
        state: { ne: $state }
        component: { eq: "Total" }
        cycle: { eq: "Overall" }
        score: { lte: $score }
        abbrev: { ne: "US Avg" }
      }
      limit: 3
    ) {
      nodes {
        code
        score
        state
        grade
      }
    }

    greaterScoreSimilarStates: allSheetDataRepresentation(
      sort: { fields: score, order: ASC }
      filter: {
        state: { ne: $state }
        component: { eq: "Total" }
        cycle: { eq: "Overall" }
        score: { gte: $score }
        abbrev: { ne: "US Avg" }
      }
      limit: 3
    ) {
      nodes {
        code
        score
        state
        grade
      }
    }
    femaleDemoGraphic: allSheetDataRepresentation(
      filter: {
        state: { eq: $state }
        component: { eq: "Female" }
        cycle: { eq: "2020" }
      }
    ) {
      nodes {
        code
        cycle
        state
        measure
        component
        subComponent
        value
        weight
      }
    }
    nonWhiteDemoGraphic: allSheetDataRepresentation(
      filter: {
        state: { eq: $state }
        component: { eq: "NonWhite" }
        cycle: { eq: "2020" }
      }
    ) {
      nodes {
        code
        cycle
        state
        measure
        component
        subComponent
        value
        weight
      }
    }

    average: allSheetDataRepresentation(
      filter: { state: { eq: "US Average" }, cycle: { eq: "Overall" } }
    ) {
      nodes {
        code
        state
        grade
        component
        measure
        cycle
        score
        value
        weight
      }
    }

    overall: allSheetDataRepresentation(
      filter: { state: { eq: $state }, cycle: { eq: "Overall" } }
    ) {
      nodes {
        code
        state
        grade
        component
        measure
        cycle
        score
        value
        weight
      }
    }

    icon: allFile(
      filter: {
        name: { eq: $state }
        ext: { eq: ".svg" }
        relativeDirectory: { eq: "images/states" }
      }
    ) {
      nodes {
        name
        publicURL
        relativeDirectory
      }
    }
  }
`;
