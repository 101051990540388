import React, { ReactNode } from "react";
import BaseComponent from "../../base/base-component";
import DemographicParityChart from "../charts/demographic-parity/demographic-parity";
import {
  IDemographicParityItem,
  IDemographicParityProperties,
} from "./demographic-parity-interface";
import "./demographic-parity.scss";

export default class DemographicParity extends BaseComponent<IDemographicParityProperties> {
  protected declareTranslateCollection(): string {
    return "demographicParity";
  }

  public render(): ReactNode {
    const { items } = this.props;

    return (
      <div className="c-box-wrap c-demographic">
        <div className="c-section">
          <h3 className="c-section__title">{this.translate("calculatingParity")}</h3>
        </div>
        <div className="row c-demographic-list">
          {items.map((item: IDemographicParityItem, index: number) => {
            const { population, legislature, title, parity, description } =
              item;

            return (
              <div key={index} className="col-lg-6 c-demographic-items">
                <h2 className="c-demographic__title">{title}</h2>
                <div className="c-demographic__wrap">
                    <DemographicParityChart
                      legislature={legislature}
                      parity={parity}
                      population={population}
                    />
                  <div className="c-demographic__info">
                    <span>{Math.round(parity)}{"%"}</span> 
                    <span>{this.translate("parity")}</span>
                  </div>
                  <div className="c-demographic__subtitle">{this.translate("population")}</div>
                </div>
                <div className="c-demographic__desc">{description}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
