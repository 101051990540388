import loadable from "@loadable/component";
import React, { ReactNode } from "react";
import BaseComponent from "../../../base/base-component";
import { IStackedColumnChartProperties } from "./stacked-column-interface";

export default class StackedColumnChart extends BaseComponent<IStackedColumnChartProperties> {
  public render(): ReactNode {
    const Chart = loadable(() => import("react-apexcharts"));
    const {
      chartSeries,
      fillColors = ["#eee"],
      fontColors = ["#000"],
      height,
      width,
      onValueShow,
    } = this.props;

    let max = 0;

    const categories = chartSeries.map((serie) => {
      const serieMax = Math.max(...serie.data);

      if (serieMax > max) max = serieMax;

      return serie.name;
    });

    return (
      <Chart
        series={chartSeries}
        options={{
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
            },
          },
          yaxis: {
            min: 0,
            max,
            show: true,
            axisBorder: {
              show: true,
              width: 2,
              color: '#7E7986',
            },
            tickAmount: 1,
            labels: {
              formatter: function (val: number, opts?: any) {
                return `${val}%`;
              },
              style:{
                colors: "#7E7986",
                fontSize: '12px',
                fontFamily: 'IBM Plex Mono',
                fontWeight: 400,
              }
            },
          },
          xaxis: {
            type: "category",
            categories,
            axisBorder: {
              color: '#7E7986',
            },
            labels: {
              style: {
                colors: "#7E7986",
                fontSize: '12px',
                fontFamily: 'IBM Plex Mono',
                fontWeight: 400,
              },
            },
          },

          fill: {
            colors: fillColors,
            opacity: 1,
          },
          legend: {
            show: false,
          },
          grid: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },

          dataLabels: {
            formatter: function (val, opts) {
              const { seriesIndex, dataPointIndex } = opts;
              return onValueShow
                ? onValueShow(seriesIndex, dataPointIndex, val)
                : (val as string);
            },
            style: {
              colors: fontColors,
              fontSize: '16px',
              fontFamily: 'IBM Plex Mono',
              fontWeight: 400,
            },
          },
        }}
        type="bar"
        height={height}
        width='70%'
      />
    );
  }
}
