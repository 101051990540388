import React, { ReactNode } from 'react';
import BaseComponent from '../../../base/base-component';
import DetailsContainer from '../container/details-container';
import DetailsItem from '../item/details-item';
import { IDetailsItem } from '../item/details-item-interface';
import { IRepresentationDetailsProperties } from './representation-details-interface';

export default class RepresentationDetails extends BaseComponent<IRepresentationDetailsProperties> {
  public render(): ReactNode {
    const { items } = this.props;

    return (
      <DetailsContainer>
        {items.map((item: IDetailsItem, index: number): ReactNode => {
          return <DetailsItem key={index} item={item} />;
        })}
      </DetailsContainer>
    );
  }
}
