import React, { ReactNode } from "react";
import BaseComponent from "../../../base/base-component";
import StackedColumnChart from "../stacked-column/stacked-column";
import { IDemographicParityChartProperties } from "./demographic-parity-interface";

export default class DemographicParityChart extends BaseComponent<IDemographicParityChartProperties> {
  protected declareTranslateCollection(): string | undefined {
    return "demographicParity";
  }

  public render(): ReactNode {
    const { legislature, parity, population } = this.props;

    return (
      <StackedColumnChart
        chartSeries={[
          {
            name: this.translate("population"),
            data: [0, legislature],
          },
          {
            name: this.translate("legislature"),
            data: [population, population - legislature],
          },
        ]}
        height={380}
        width={260}
        fontColors={["#fff", "#4A1D96"]}
        fillColors={["#4A1D96", "#EDE6FA"]}
        onValueShow={(serieIndex, pointIndex, value) => {
          if (serieIndex === 1 && pointIndex === 1) return "";
          return `${value}%`;
        }}
      />
    );
  }
}
